<template>
  <div class="reward-leaderboard-page">
    <h1>Leaderboard</h1>
    <table class="reward-leaderboard-page-table">
      <thead>
        <tr>
          <th>Referal Address</th>
          <th>Referal Count</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, idx) in referalCount"
          :key="idx"
        >
          <td>
            {{ item.address }}
          </td>
          <td>
            {{ item.count }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="reward-leaderboard-buyers">
      <h2>Welcome new BAP holders!</h2>
      <div class="reward-leaderboard-buyers-items">
        <div
          v-for="(item, idx) in buyerAddresses"
          :key="idx"
          class="reward-leaderboard-buyers-item"
        >
          {{ item.buyer_address }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      buyerAddresses: [],
      referalCount: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const { data } = await axios.get(
        "https://api.hypereveal.com/v1/bap/rewards"
      );
      data.rankings.map((ranking) => {
        const { referrer_address, reward_count, rewards } = ranking;
        this.referalCount.push({
          address: referrer_address,
          count: reward_count,
        });
        this.buyerAddresses = [...this.buyerAddresses, ...rewards];
      });
      console.log(data);
    },
  },
};
</script>
